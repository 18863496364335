import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { encodeQuery, urlJoin } from '@zedoc/url';
import { useDDPCall, useDDPQuery } from '@zedoc/ddp-connector';
import { default as ProjectTrackSelect } from '../../../common/selectors/ProjectTrack';
import { default as ProjectMilestoneSelect } from '../../../common/selectors/ProjectMilestone';
import { getResumeToken } from '../../../common/utilsClient/ddp/selectors';
import Tag from '../../../common/components/Tag';
import settings from '../../../common/settings';
import { apiZedocUpdateSurveyLink } from '../../../common/api/zedoc';
import Button from '../../../components/Button';
import Skeleton from '../../../components/Skeleton';
import { getUserNames } from '../../../common/api/aggregations/Projects';
import LocalDate from '../../../components/plates/LocalDate';

const { backendUrl = '' } =
  /** @type {{ backendUrl?: string, rootDomain?: string}} */ (
    settings.public || {}
  );

const SurveyLinksLink = ({
  id,
  name,
  createdAt,
  createdBy,
  trackId,
  projectId,
  milestoneId,
  url,
  jwtPayload,
  inactive,
  expiresIn,
}) => {
  const { t } = useTranslation();

  const { value: userNames } = useDDPQuery(
    projectId &&
      getUserNames.withParams({
        projectId,
      }),
  );

  const track = useSelector(ProjectTrackSelect.one().whereIdEquals(trackId));
  const milestone = useSelector(
    ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
  );

  const [qrcodeUrl, setQrCodeUrl] = useState(null);
  const resumeToken = useSelector(getResumeToken);

  useEffect(() => {
    if (!url) {
      return;
    }
    const apiUrl = `${urlJoin(backendUrl, '/api/v1/qrcode.png')}${encodeQuery({
      url,
    })}`;
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'X-Auth-Token': resumeToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Failed to load image');
      })
      .then((blob) => {
        setQrCodeUrl(URL.createObjectURL(blob));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setQrCodeUrl(null);
      });
  }, [url, resumeToken, setQrCodeUrl]);

  const isJwtPayloadActive = useMemo(() => {
    const now = new Date().getTime() / 1000;
    if (jwtPayload.nbf && now < jwtPayload.nbf) {
      return false;
    }
    if (jwtPayload.exp && now > jwtPayload.exp) {
      return false;
    }
    return true;
  }, [jwtPayload]);
  const isActive = useMemo(
    () => !inactive && isJwtPayloadActive,
    [inactive, isJwtPayloadActive],
  );
  const { ddpCall, ddpIsPending } = useDDPCall();
  const handleOnActivityStateToggle = useCallback(() => {
    ddpCall(
      apiZedocUpdateSurveyLink.withParams({
        id,
        projectId,
        inactive: !inactive,
      }),
    );
  }, [ddpCall, id, projectId, inactive]);

  const isExpired = useMemo(() => {
    const now = new Date().getTime() / 1000;
    if (jwtPayload.exp && now > jwtPayload.exp) {
      return true;
    }
    return false;
  }, [jwtPayload]);

  const isPlanned = useMemo(() => {
    const now = new Date().getTime() / 1000;
    if (jwtPayload.nbf && now < jwtPayload.nbf) {
      return true;
    }
    return false;
  }, [jwtPayload]);

  const currentState = useMemo(() => {
    if (isExpired) {
      return t('stateMachines:Activity.states.expired');
    }

    if (isPlanned) {
      return t('stateMachines:Activity.states.planned');
    }

    if (inactive) {
      return t('stateMachines:Activity.states.suspended');
    }

    return t('stateMachines:Activity.states.active');
  }, [isExpired, isPlanned, inactive, t]);

  const currentStateType = useMemo(() => {
    if (isActive) {
      return 'success';
    }

    if (isPlanned) {
      return 'info';
    }

    return 'danger';
  }, [isActive, isPlanned]);

  return (
    <div className="cluster-4 justify-between items-center">
      <div className="stack-2">
        <div className="cluster-2 items-center">
          <div className="self-start">
            <Tag size="small" type={currentStateType}>
              {currentState}
            </Tag>
          </div>
        </div>
        <div className="stack-0">
          {name && (
            <div>
              <span className="font-semibold">{t('forms:name.label')}:</span>{' '}
              {name}
            </div>
          )}
          <div>
            <span className="font-semibold">{t('createdAt')}:</span>{' '}
            <LocalDate
              date={createdAt}
              projectId={projectId}
              showTime
              showTimezone
            />
          </div>
          <div>
            <span className="font-semibold">{t('createdBy')}:</span>{' '}
            {userNames?.[createdBy]}
          </div>
          <div>
            <span className="font-semibold">{t('track')}:</span> {track?.name}
          </div>
          <div>
            <span className="font-semibold">{t('milestone')}:</span>{' '}
            {milestone?.getName()}
          </div>
          {jwtPayload.nbf && (
            <div>
              <span className="font-semibold">
                {t('forms:dateStart.label')}:
              </span>{' '}
              <LocalDate
                date={new Date(jwtPayload.nbf * 1000)}
                projectId={projectId}
                showTimezone
              />
            </div>
          )}
          <div>
            <span className="font-semibold">{t('forms:dateEnd.label')}:</span>{' '}
            <LocalDate
              date={new Date(jwtPayload.exp * 1000)}
              projectId={projectId}
              showTimezone
            />
          </div>
          {expiresIn && (
            <div>
              <span className="font-semibold">
                {t('forms:sessionDurationInSeconds.label')}:
              </span>{' '}
              {expiresIn}
            </div>
          )}
        </div>
      </div>
      <div className="stack-2">
        <a href={url} target="_blank noreferrer">
          {qrcodeUrl ? (
            <img src={qrcodeUrl} alt={t('qrcode')} height="160" width="160" />
          ) : (
            <Skeleton className="h-40 w-40" block={false} />
          )}
        </a>
        {isJwtPayloadActive && (
          <div className="self-center">
            <Button
              type="ghost"
              size="small"
              onClick={handleOnActivityStateToggle}
              loading={ddpIsPending}
            >
              {isActive
                ? t('stateMachines:Activity.transitions.suspend')
                : t('stateMachines:Activity.transitions.resume')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

SurveyLinksLink.propTypes = {
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  createdBy: PropTypes.string.isRequired,
  trackId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  milestoneId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  jwtPayload: PropTypes.object.isRequired,
  name: PropTypes.string,
  inactive: PropTypes.bool,
  expiresIn: PropTypes.number,
};

SurveyLinksLink.defaultProps = {
  name: null,
  inactive: false,
  expiresIn: null,
};

export default SurveyLinksLink;
