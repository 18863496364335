// @ts-nocheck
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import includes from 'lodash/includes';
import { slugify } from '@zedoc/text';
import { yearMonthDayShift } from '@zedoc/date';
import {
  PROJECT_MILESTONE_TYPE__SCHEDULED,
  PROJECT_MILESTONE_TYPE__CUSTOM,
  PROJECT_MILESTONE_TYPE__CONSENT,
} from '../constants';
import BaseModel from './BaseModel';
import MilestoneDependency from './MilestoneDependency';

class ProjectMilestone extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }

  getProjectId() {
    return this.projectId;
  }

  getName() {
    return this.name || '';
  }

  getSlug() {
    return slugify(this.getName()).replace(/-/g, '_');
  }

  isCustom() {
    return this.type === PROJECT_MILESTONE_TYPE__CUSTOM;
  }

  isScheduled() {
    return this.type === PROJECT_MILESTONE_TYPE__SCHEDULED;
  }

  isConsent() {
    return this.type === PROJECT_MILESTONE_TYPE__CONSENT;
  }

  isNonActionable() {
    return this.nonActionable;
  }

  getSchedule(isoString) {
    const schedule = {};
    if (isoString) {
      const yearMonthDay = isoString.substr(0, 10);
      // NOTE: Negative value will be interpreted as "infinity", i.e. dateStart will not be set.
      if (isNil(this.minusDays) || this.minusDays >= 0) {
        schedule.dateStart = yearMonthDayShift(
          yearMonthDay,
          -(this.minusDays || 0),
        );
      }
      if (isNil(this.plusDays) || this.plusDays >= 0) {
        schedule.dateEnd = yearMonthDayShift(yearMonthDay, this.plusDays || 0);
      }
      if (
        !this.timeStart ||
        !this.timeEnd ||
        !this.dateEnd ||
        !this.dateStart ||
        this.dateStart < this.dateEnd ||
        this.timeStart <= this.timeEnd
      ) {
        // NOTE: If something is wrong with timeStart/timeEnd,
        //       just skip it. This is our last line of defense in case
        //       milestone was improperly configured.
        //       Theoretically this shouldn't happen but it was
        //       observed at least once due to data corruption.
        if (this.timeStart) {
          schedule.timeStart = this.timeStart;
        }
        if (this.timeEnd) {
          schedule.timeEnd = this.timeEnd;
        }
      }
    }
    return schedule;
  }

  getCommonAssigneeTypes(assigneeTypes) {
    return intersection(
      map(this.questionnaires, 'assigneeType'),
      assigneeTypes,
    );
  }

  hasBaselineSchedule() {
    // NOTE: Maybe in the future we will add another condition, e.g.
    //       this.isConsent() && this.consentHasGracePeriod
    return this.isScheduled();
  }

  isAllowedInTrack(trackId) {
    return !this.selectedTracksOnly || includes(this.selectedTracks, trackId);
  }

  getBaselineSchedule(yearMonthDay) {
    if (!this.hasBaselineSchedule() || !yearMonthDay) {
      return {};
    }
    return this.getSchedule(
      yearMonthDayShift(yearMonthDay, this.daysSinceBaseline || 0),
    );
  }

  getDependencies() {
    return map(this.dependencies, (options) => {
      return MilestoneDependency.create(options);
    });
  }
}

ProjectMilestone.scopeName = '@milestone';
ProjectMilestone.collection = 'ProjectMilestones';

export default ProjectMilestone;
