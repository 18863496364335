// TODO: Refine enums and string formats.
const ProjectConfiguration = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    logoUrl: {
      type: 'string',
    },
    timezone: {
      type: 'string',
    },
    fallbackLanguage: {
      type: 'string',
    },
    otherSupportedLanguages: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    requirePatientAuth: {
      type: 'boolean',
    },
    whenAuthNotPossible: {
      type: 'string',
    },
    allowUseOfSurveyLinks: {
      type: 'boolean',
    },
    questionnaires: {
      type: 'array',
      items: {
        type: 'object',
        required: ['identifier', 'version'],
        properties: {
          identifier: {
            type: 'string',
          },
          version: {
            type: 'string',
          },
          namespace: {
            type: 'string',
          },
          initialBindings: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                variableId: {
                  type: 'string',
                },
                questionnaireVariableId: {
                  type: 'string',
                },
              },
            },
          },
          finalComputations: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                name: {
                  type: 'string',
                },
                questionnaireVariableId: {
                  type: 'string',
                },
                expression: {
                  type: 'string',
                },
              },
            },
          },
          finalBindings: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                variableId: {
                  type: 'string',
                },
                questionnaireVariableId: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    variables: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          compulsory: {
            type: 'boolean',
          },
          disableUserEdits: {
            type: 'boolean',
          },
        },
      },
    },
    csvSchemata: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'name'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          columns: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                header: {
                  type: 'string',
                },
                bindingType: {
                  type: 'string',
                },
                variableId: {
                  type: 'string',
                },
                isUniqueIdentifier: {
                  type: 'boolean',
                },
                uniqueIdentifierScope: {
                  type: 'string',
                },
                uniqueIdentifierScopeName: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    tracks: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
        },
      },
    },
    milestones: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          plusDays: {
            type: 'integer',
          },
          minusDays: {
            type: 'integer',
          },
          timeStart: {
            type: 'string',
          },
          timeEnd: {
            type: 'string',
          },
          daysSinceBaseline: {
            type: 'integer',
          },
          nonActionable: {
            type: 'boolean',
          },
          questionnaires: {
            type: 'array',
            items: {
              type: 'object',
              required: ['identifier', 'assigneeType'],
              properties: {
                identifier: {
                  type: 'string',
                },
                assigneeType: {
                  type: 'string',
                },
                delegatedToPatient: {
                  type: 'boolean',
                },
              },
            },
          },
          dependencies: {
            type: 'array',
            items: {
              type: 'object',
              required: ['milestoneId'],
              properties: {
                milestoneId: {
                  type: 'string',
                },
                condition: {
                  type: 'string',
                  // enum: [
                  //   'questionnaireAssigneesCompleted',
                  //   'activityCompleted',
                  // ],
                },
                assigneeTypes: {
                  type: 'array',
                  items: {
                    type: 'string',
                  },
                },
              },
            },
          },
          delayDays: {
            type: 'number',
          },
          recurring: {
            type: 'boolean',
          },
          maxRepetitions: {
            type: 'integer',
          },
          repeatAfterDays: {
            type: 'integer',
          },
          selectedTracksOnly: {
            type: 'boolean',
          },
          selectedTracks: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
    notifications: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'delivery'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          type: {
            type: 'string',
          },
          eventType: {
            type: 'string',
          },
          group: {
            type: 'string',
          },
          delivery: {
            type: 'string',
          },
          useOnlyFirstMatchingDestination: {
            type: 'boolean',
          },
          destinations: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                },
                useVariable: {
                  type: 'boolean',
                },
                variableId: {
                  type: 'string',
                },
                address: {
                  type: 'string',
                },
              },
            },
          },
          templates: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          selectedMilestonesOnly: {
            type: 'boolean',
          },
          selectedMilestones: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          selectedTracksOnly: {
            type: 'boolean',
          },
          selectedTracks: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          delayMinutes: {
            type: 'integer',
          },
          delayDays: {
            type: 'integer',
          },
          assigneeTypes: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
    inAppContents: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name', 'type'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          type: {
            type: 'string',
            // enum: ['welcome', 'FAQ', 'medical_information', 'authentication', 'project_details'],
          },
          selectedMilestonesOnly: { type: 'boolean' },
          selectedMilestones: {
            type: 'array',
            items: { type: 'string' },
          },
          selectedTracksOnly: { type: 'boolean' },
          selectedTracks: {
            type: 'array',
            items: { type: 'string' },
          },
          translations: {
            type: 'array',
            items: {
              type: 'object',
              required: ['language', 'content'],
              properties: {
                language: {
                  type: 'string',
                },
                subject: {
                  type: 'string',
                },
                content: {
                  type: 'string',
                },
              },
            },
          },
          authSettings: {
            type: 'object',
            properties: {
              variableId: {
                type: 'string',
              },
              widgetType: {
                type: 'string',
                enum: ['text', 'date', 'pin'],
              },
              maxCharacters: {
                type: 'number',
              },
              digitsOnly: {
                type: 'boolean',
              },
              trimmingType: {
                type: 'string',
                enum: ['start', 'end'],
              },
            },
          },
        },
      },
    },
    messageTemplateBindings: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          templateVariableId: {
            type: 'string',
          },
          variableId: {
            type: 'string',
          },
        },
      },
    },
    messageTemplates: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name', 'purpose', 'content'],
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          purpose: {
            type: 'string',
          },
          language: {
            type: 'string',
          },
          subject: {
            type: 'string',
          },
          content: {
            type: 'string',
          },
          htmlContent: {
            type: 'string',
          },
        },
      },
    },
    eappRules: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          priority: {
            type: 'string',
            // enum: [
            //   'low',
            //   'medium',
            //   'high',
            // ],
          },
          triggerType: {
            type: 'string',
          },
          triggerOnActivityStateTransition: {
            type: 'string',
          },
          assigneeTypes: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          selectedMilestonesOnly: {
            type: 'boolean',
          },
          selectedMilestones: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          selectedQuestionnairesOnly: {
            type: 'boolean',
          },
          selectedQuestionnaires: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
          variables: {
            type: 'string',
          },
          condition: {
            type: 'string',
          },
          actionsToTrigger: {
            type: 'array',
            items: {
              type: 'object',
              required: ['id', 'type'],
              properties: {
                id: {
                  type: 'string',
                },
                type: {
                  type: 'string',
                },
                useVariables: {
                  type: 'boolean',
                },
                variables: {
                  type: 'string',
                },
                milestoneId: {
                  type: 'string',
                },
                notificationId: {
                  type: 'string',
                },
                trackId: {
                  type: 'string',
                },
              },
            },
          },
        },
      },
    },
    dashboards: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          title: {
            type: 'string',
          },
          type: {
            type: 'string',
            // enum: [
            //   'analytics',
            //   'timeline',
            //   'documents',
            //   'details',
            //   'latestResponses',
            //   'analyticsTable',
            //   'EAPP',
            //   'EAPPv2',
            //   'projectLandingPage',
            // ],
          },
          perspective: {
            type: 'string',
          },
          settings: {
            type: 'string',
          },
          columns: {
            type: 'array',
            items: {
              type: 'object',
              required: ['id', 'title', 'formula'],
              properties: {
                id: {
                  type: 'string',
                },
                title: {
                  type: 'string',
                },
                formula: {
                  type: 'string',
                },
                align: {
                  type: 'string', // left | right | center
                },
                status: {
                  type: 'string', // formula evaluating to one of: warning | danger | success
                },
              },
            },
          },
          variables: {
            type: 'string',
          },
          charts: {
            type: 'array',
            items: {
              type: 'object',
              required: ['id', 'title', 'type'],
              properties: {
                id: {
                  type: 'string',
                },
                title: {
                  type: 'string',
                },
                type: {
                  type: 'string',
                },
                settings: {
                  type: 'string',
                },
                dataProvider: {
                  type: 'string',
                },
                dataProviderCleanup: {
                  type: 'string',
                },
                detailedViewTransformation: {
                  type: 'string',
                },
              },
            },
          },
          cards: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                id: {
                  type: 'string',
                },
                title: {
                  type: 'string',
                },
                valueAxisTitle: {
                  type: 'string',
                },
                valueAxisMinimum: {
                  type: 'number',
                },
                valueAxisMaximum: {
                  type: 'number',
                },
                chartPrecision: {
                  type: 'number',
                },
                chartGuides: {
                  type: 'string',
                },
                chartType: {
                  type: 'string',
                  // enum: [
                  //   'totalScore',
                  //   'totalScoreIndividual',
                  //   'totalScoreNoMilestones',
                  //   'totalScoreNoMilestonesIndividual',
                  //   'propertyBreakdown',
                  //   'propertyBreakdown2',
                  //   'propertyBreakdownIndividual',
                  //   'propertyBreakdown2Individual',
                  //   'propertyFiveValuesBoxPlot',
                  //   'propertyHistogram',
                  //   'propertyHistogramIndividual',
                  //   'questionBreakdown',
                  //   'fiveValuesBoxPlot',
                  //   'averageValue',
                  //   'topAverageValues',
                  //   'averageValues',
                  //   'radarAverageScoreAndVariable',
                  //   'answersSheetStateByDateScheduled',
                  //   'averageValueAgainstMilestone',
                  //   'averageValueAgainstAnotherValue',
                  //   'valueChoicesAgainstMilestone',
                  //   'valueChoicesAgainstAnotherValue',
                  //   'mostRecentNumericValuePieChart',
                  //   'mostRecentValuePieChart',
                  // ],
                },
                chartSettings: {
                  type: 'string',
                },
                chartSize: {
                  type: 'string',
                  // enum: [
                  //   'small',
                  //   'medium',
                  //   'large',
                  //   'xlarge',
                  //   'xtall',
                  // ],
                },
                chartVariants: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      value: {
                        type: 'string',
                      },
                      label: {
                        type: 'string',
                      },
                      params: {
                        type: 'string',
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export default ProjectConfiguration;
