import { css } from 'styled-components';
import theming from 'styled-theming';
import {
  BRANDING_TYPE__ZEDOC,
  BRANDING_TYPE__ZESIGHT,
  BRANDING_TYPE__CLINICIAN,
} from '../common/constants/branding';
import settings from '../common/settings';
import { default as pattern } from '../assets/patterns/topography.svg';
import entry from '../assets/entry.png';

const entryScreenBackground = theming('mode', {
  light: css`
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url(${entry});
  `,
  dark: css`
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${entry});
  `,
});

const getUIBranding = () => settings.public.branding || BRANDING_TYPE__ZEDOC;
const getI18nBranding = () => {
  switch (settings.public.branding) {
    case BRANDING_TYPE__ZESIGHT:
      return BRANDING_TYPE__ZESIGHT;
    default:
      return BRANDING_TYPE__ZEDOC;
  }
};

export const getProductName = () => {
  return process.env.REACT_APP_TITLE;
};

export const getEntryScreenBackground = () => {
  switch (getUIBranding()) {
    case BRANDING_TYPE__ZESIGHT:
    case BRANDING_TYPE__CLINICIAN:
      return theming('mode', {
        light: css`
          background-image: linear-gradient(
              rgba(242, 242, 243, 0.975),
              rgba(242, 242, 243, 0.975)
            ),
            url(${pattern});
        `,
        dark: css`
          background-image: linear-gradient(
              rgba(30, 38, 48, 0.925),
              rgba(30, 38, 48, 0.925)
            ),
            url(${pattern});
        `,
      });
    default:
      return entryScreenBackground;
  }
};

// NOTE: We usually use default export to be used as "context: branding" with i18n.
// And with that ZEDOC context is preferred.
export default getI18nBranding();
export const uiBranding = getUIBranding();
