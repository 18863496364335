import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import isNil from 'lodash/isNil';
import { useDDPSubscription } from '@zedoc/ddp-connector';
import {
  apiZedocOneProject,
  apiZedocCreateSurveyLink,
} from '../../../common/api/zedoc';
import { notifyError, notifySuccess } from '../../../utils/notify';
import { callMethod } from '../../../store/ddpActions';
import Tabs from '../../../components/layouts/Tabs';
import { default as ProjectSelect } from '../../../common/selectors/Project';
import { default as ProjectTrackSelect } from '../../../common/selectors/ProjectTrack';
import { default as ProjectMilestoneSelect } from '../../../common/selectors/ProjectMilestone';
import usePermission from '../../../utils/usePermission';
import Skeleton from '../../../components/Skeleton';
import { PROJECT_CREATE_SURVEY_LINK } from '../../../common/permissions';
import { getSurveyLinksDialog } from '../selectors';
import { closeSurveyLinksDialog } from '../actions';
import SurveyLinkForm from './SurveyLinkForm';
import Dialog from '../../../components/Dialog';
import SurveyLinksLink from './SurveyLinksLink';

const SurveyLinksDialog = ({ projectId }) => {
  const surveyLinkDialog = useSelector(getSurveyLinksDialog);

  const { ready: projectReady } = useDDPSubscription(
    apiZedocOneProject.withParams({ projectId }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const tracks = useSelector(ProjectTrackSelect.all().where({ projectId }));
  const milestones = useSelector(
    ProjectMilestoneSelect.all().where({ projectId }),
  );

  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeSurveyLinksDialog());
  }, [dispatch]);

  const canCreateSurveyLink = usePermission([PROJECT_CREATE_SURVEY_LINK], {
    relativeTo: project && project.getDomains(),
  });

  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('newLink');

  const surveyLinkForm = useRef();
  const handleOnCreateNewLink = useCallback(() => {
    surveyLinkForm.current
      ?.submit()
      .then((formValues) => {
        const { dateStart, dateEnd, milestoneId, trackId, expiresIn, name } =
          formValues;
        const params = {
          name,
          projectId,
          trackId,
          milestoneId,
          dateEnd,
        };
        if (dateStart) {
          params.dateStart = dateStart;
        }
        if (!isNil(expiresIn)) {
          params.expiresIn = expiresIn;
        }
        return dispatch(callMethod(apiZedocCreateSurveyLink, params));
      })
      .then(notifySuccess('Link created successfully'))
      .then(() => setActiveKey('pastLinks'))
      .catch(notifyError());
  }, [dispatch, projectId]);

  return (
    <Dialog
      size="large"
      title={t('permissions:project.createSurveyLink')}
      visible={!!surveyLinkDialog}
      okText={t('create')}
      onOk={handleOnCreateNewLink}
      isOkDisabled={!canCreateSurveyLink}
      onCancel={handleOnCancel}
    >
      {projectReady ? (
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          tabs={[
            {
              value: 'newLink',
              label: t('newLink'),
              content: (
                <SurveyLinkForm
                  ref={surveyLinkForm}
                  project={project}
                  milestones={milestones}
                  tracks={tracks}
                />
              ),
            },
            {
              value: 'pastLinks',
              label: t('pastLinks'),
              content: (
                <div className="stack-4">
                  {map(
                    orderBy(project.surveyLinks, 'createdAt', 'desc'),
                    (surveyLink, i) => (
                      <>
                        {i !== 0 && <hr className="border-divider" />}
                        <SurveyLinksLink
                          id={surveyLink.id}
                          name={surveyLink.name}
                          createdAt={surveyLink.createdAt}
                          createdBy={surveyLink.createdBy}
                          trackId={surveyLink.trackId}
                          projectId={surveyLink.projectId}
                          milestoneId={surveyLink.milestoneId}
                          url={surveyLink.url}
                          jwtPayload={surveyLink.jwtPayload}
                          inactive={surveyLink.inactive}
                          expiresIn={surveyLink.expiresIn}
                        />
                      </>
                    ),
                  )}
                </div>
              ),
            },
          ]}
        />
      ) : (
        <div className="stack-4">
          <Skeleton className="h-10" />
          <Skeleton className="h-24" />
        </div>
      )}
    </Dialog>
  );
};

SurveyLinksDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default SurveyLinksDialog;
