import isNaN from 'lodash/isNaN';
import find from 'lodash/find';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import {
  Questionnaire,
  isMatchingVersion,
  splitQuestionnaireId,
} from '@zedoc/questionnaire';
import { createUtcToLocalTime, zoneToUtcOffset } from '@zedoc/date';
import { strToBase64Url } from '@zedoc/text';
import { mergeLanguagePreference } from '@zedoc/i18n';
import { isValidDateString } from '@zedoc/check-schema';
import {
  YEAR_MONTH_DAY,
  MESSAGE_PURPOSE__GREET_PATIENT,
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
} from '../constants';
import ProjectVariable from './ProjectVariable';
import BaseModel from './BaseModel';
import { default as appSettings } from '../settings';

const { defaultTimezone = 'UTC' } = appSettings.private || {};
const enableSurveyLinks = !!(
  appSettings.public &&
  appSettings.public.features &&
  appSettings.public.features.enableSurveyLinks
);

class Project extends BaseModel {
  constructor(doc) {
    super(doc);
    this.variables = map(
      this.variables,
      (rawVariable) => new ProjectVariable(rawVariable, this),
    );
  }

  getName() {
    return this.name;
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getLanguagePreference(patientLanguagePreference, templatesDB = {}) {
    const supportedLanguages = [];
    if (this.fallbackLanguage) {
      supportedLanguages.push(this.fallbackLanguage);
      if (this.otherSupportedLanguages) {
        supportedLanguages.push(...this.otherSupportedLanguages);
      }
    } else {
      // NOTE: If fallbackLanguage is not explicitly specified for that project
      //       we will use the welcome message templates to deduce what the
      //       supported languages should be.
      const defaultSupportedLanguages = map(
        sortBy(
          filter(templatesDB, {
            projectId: this._id,
            purpose: MESSAGE_PURPOSE__GREET_PATIENT,
          }),
          'index',
        ),
        'language',
      );
      supportedLanguages.push(...defaultSupportedLanguages);
    }
    return mergeLanguagePreference(
      patientLanguagePreference,
      supportedLanguages,
    );
  }

  getTimezone() {
    // NOTE: The defaultTimezone is added automatically by server in project details subscription.
    return (
      this.timezone ||
      this.defaultTimezone ||
      this.constructor.getDefaultTimezone()
    );
  }

  getMomentInLocalTime(timestamp = Date.now()) {
    return this.constructor.getMomentInTimezone(this.getTimezone(), timestamp);
  }

  getCurrentYearMonthDay(timestamp) {
    return this.getMomentInLocalTime(timestamp).format(YEAR_MONTH_DAY);
  }

  canUseSurveyLinks() {
    return enableSurveyLinks && !!this.allowUseOfSurveyLinks;
  }

  /**
   * @param {string} fullDate - 'YYYY-MM-DD'
   * @returns {Date | null}
   */
  getStartOfDay(fullDate) {
    if (!isValidDateString(fullDate)) {
      return null;
    }
    const utcToLocalTime = createUtcToLocalTime(this.getTimezone(), {
      noStrict: true,
    });
    return utcToLocalTime(new Date(`${fullDate}T00:00:00.000Z`));
  }

  /**
   * @param {string} fullDate - 'YYYY-MM-DD'
   * @returns {Date | null}
   */
  getEndOfDay(fullDate) {
    if (!isValidDateString(fullDate)) {
      return null;
    }
    const utcToLocalTime = createUtcToLocalTime(this.getTimezone(), {
      noStrict: true,
    });
    return utcToLocalTime(new Date(`${fullDate}T23:59:59.999Z`));
  }

  applyQuestionnaireDefaults(milestoneQuestionnaires) {
    const questionnaires = [];
    const byIdentifier = keyBy(this.questionnaires, 'identifier');
    forEach(milestoneQuestionnaires, (settings) => {
      const questionnaire = byIdentifier[settings.identifier];
      if (questionnaire) {
        const { version, identifier, navigationTypes } = questionnaire;
        const defaultNavigationType = navigationTypes && navigationTypes[0];
        let { navigationType } = settings;
        if (navigationType || !includes(navigationTypes, navigationType)) {
          navigationType = defaultNavigationType;
        }
        questionnaires.push({
          ...settings,
          version,
          identifier,
          navigationTypes,
          id: `${identifier}@${version}`,
          navigationType: settings.navigationType || defaultNavigationType,
        });
      }
    });
    return questionnaires;
  }

  getRelatedVariablesIds() {
    const { variables, csvSchemata, questionnaires, messageTemplateBindings } =
      this;
    return uniq([
      ...map(variables, 'id'),
      ...map(messageTemplateBindings, 'variableId'),
      ...flatMap(csvSchemata, ({ columns }) => {
        return compact(map(columns, 'variableId'));
      }),
      ...flatMap(questionnaires, ({ finalBindings, initialBindings }) => {
        return [
          ...map(initialBindings, 'variableId'),
          ...map(finalBindings, 'variableId'),
        ];
      }),
    ]);
  }

  getProjectDescription() {
    return this.description || '';
  }

  getQuestionnaireConfig(questionnaireId) {
    const [identifier, version] = splitQuestionnaireId(questionnaireId);
    return find(this.questionnaires, (config) => {
      return (
        config.identifier === identifier &&
        isMatchingVersion(config.version, version)
      );
    });
  }

  compileFinalComputations(rawQuestionnaire = {}) {
    const { _id: questionnaireId } = rawQuestionnaire;
    const questionnaireConfig = this.getQuestionnaireConfig(questionnaireId);
    if (
      !questionnaireConfig ||
      isEmpty(questionnaireConfig.finalComputations)
    ) {
      return [];
    }
    return Questionnaire.compileFinalComputations(
      rawQuestionnaire,
      questionnaireConfig.finalComputations,
    );
  }

  isActive() {
    return !!this.active;
  }

  getCompleted() {
    return this.totalCompleted || 0;
  }

  getDeclined() {
    return this.totalDeclined || 0;
  }

  getInProgress() {
    return this.totalInProgress || 0;
  }

  getNumberOfMilestones() {
    return this['summary:milestones'] || 0;
  }

  getNumberOfTracks() {
    return this['summary:tracks'] || 0;
  }

  getNumberOfParticipations() {
    return sumBy(this['summary:participations'], 'total');
  }

  getNumberOfActivities() {
    return sumBy(this['summary:activities'], 'total');
  }

  getNumberOfCompletedActivities() {
    return sumBy(
      filter(this['summary:activities'], { state: ACTIVITY_STATE__COMPLETED }),
      'total',
    );
  }

  getNumberOfDueActivities() {
    return sumBy(
      filter(this['summary:activities'], { state: ACTIVITY_STATE__ACTIVE }),
      'total',
    );
  }

  getNumberOfUsers() {
    return this['summary:users'] || 0;
  }

  getWorkerMetadata(workerName) {
    const key = this.constructor.getWorkerMetadataKey(workerName);
    return this[key] || {};
  }

  static getWorkerMetadataKey(workerName) {
    return `_worker:${strToBase64Url(workerName)}`;
  }

  static getDefaultTimezone() {
    return defaultTimezone;
  }

  static getMomentInTimezone(
    timezone = this.getDefaultTimezone(),
    timestamp = Date.now(),
  ) {
    const offset = zoneToUtcOffset(timezone)(timestamp);
    if (isNaN(offset)) {
      return moment.invalid();
    }
    return moment(timestamp).utcOffset(offset);
  }
}

Project.scopeName = '@project';
Project.collection = 'Projects';

export default Project;
