// @ts-nocheck
import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import BaseModel from './BaseModel';
import {
  ACTIVITY_STATES_AFTER_START,
  ACTIVITY_ACTION__START,
  ACTIVITY_ACTION__COMPLETE,
} from '../constants';
import isSubset from '../utils/isSubset';

class Activity extends BaseModel {
  get status() {
    const status = reduce(
      this.actions,
      this.constructor.reducer,
      this.constructor.reducer(undefined, {
        type: '@INIT',
      }),
    );
    Object.defineProperty(this, 'status', {
      value: status,
    });
    return status;
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getProjectId() {
    return this.projectId;
  }

  getMilestoneId() {
    return this.milestoneId;
  }

  getEncounterId() {
    return this.encounterId;
  }

  getParticipationId() {
    return this.participationId;
  }

  getPatientServiceId() {
    return this.patientServiceId || this._id;
  }

  isStarted() {
    return includes(ACTIVITY_STATES_AFTER_START, this.state);
  }

  isCompletedBy(assigneeTypes) {
    return isSubset(assigneeTypes, this.completedSteps);
  }
}

Activity.collection = 'Activities';
Activity.scopeName = '@activity';
Activity.piiFields = ['identifiers'];
Activity.reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_ACTION__START: {
      return {
        ...state,
        started: true,
        startedAt: action.meta && action.meta.timestamp,
      };
    }
    case ACTIVITY_ACTION__COMPLETE:
      return {
        ...state,
        completed: true,
        completedAt: action.meta && action.meta.timestamp,
      };
    default:
      return state;
  }
};

Activity.constraintsVersion = 2;
Activity.constraints = [
  {
    operator: '$lte',
    id: 'dateStartLteDateEnd',
    fields: [
      {
        name: 'dateStart',
        message: 'Expected dateStart to be smaller or equal to dateEnd',
      },
      {
        name: 'dateEnd',
        message: 'Expected dateEnd to be greater or equal to dateStart',
      },
    ],
  },
  {
    operator: '$or',
    id: 'timeStartLteTimeEnd',
    fields: [
      {
        name: 'dateStart',
        message: 'Expected dateStart to be smaller than dateEnd',
      },
      {
        name: 'dateEnd',
        message: 'Expected dateEnd to be greater than dateStart',
      },
      {
        name: 'timeStart',
        message: 'Expected timeStart to be smaller or equal to timeEnd',
      },
      {
        name: 'timeEnd',
        message: 'Expected timeEnd to be greater or equal to timeStart',
      },
    ],
    operands: [
      {
        operator: '$lt',
        operands: ['$dateStart', '$dateEnd'],
      },
      {
        operator: '$lte',
        operands: ['$timeStart', '$timeEnd'],
      },
    ],
  },
];

export default Activity;
