import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { mergeErrors, checkSchema } from '@zedoc/check-schema';
import checkConstraints from '../utils/checkConstraints';
import logger from '../logger';

/**
 * @template {object} T
 */
class BaseModel {
  /**
   * @param {T} doc
   * @param {object} options
   * @param {boolean} [options.doNotSetProperties]
   */
  constructor(doc, options = {}) {
    if (!options.doNotSetProperties) {
      Object.assign(this, doc);
    }
    Object.defineProperty(this, 'raw', {
      value: BaseModel.getRawDoc(doc),
    });
    /** @type {T} */
    // eslint-disable-next-line no-unused-expressions
    this.raw;
  }

  /**
   * @param {Record<string, import('./Variable').default>} variablesDb
   * @returns {Record<string, unknown>}
   */
  getAllVariables(variablesDb) {
    const { scopeName } = /** @type {typeof BaseModel<T>} */ (this.constructor);
    /** @type {Record<string, unknown>} */
    const variables = {};
    forEach(variablesDb, (variable, variableId) => {
      if (variable.scopeName === scopeName) {
        variables[variableId] = variable.getValue(this);
      }
    });
    return variables;
  }

  /**
   * @template {object} T
   * @param {T} doc
   * @returns {T}
   */
  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof BaseModel && 'raw' in rawDoc) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  /**
   * @this {new (...args: unknown[]) => never}
   */
  static get create() {
    /**
     * @template {object} T
     * @template {BaseModel<T>} U
     * @this {new (doc: T) => U}
     * @param {T} doc
     * @returns {U}
     */
    return (doc) => new this(doc);
  }

  /**
   * @param {unknown} doc
   * @returns {object | undefined}
   */
  static validate(doc) {
    /** @type {object | undefined} */
    let errors;
    if (this.schema) {
      errors = checkSchema(this.schema, doc);
    }
    if (this.constraints) {
      // NOTE: Schema errors takes precedence here, so we merge the on top of constraints.
      errors = mergeErrors(checkConstraints(this.constraints, doc), errors);
    }
    if (isEmpty(errors)) {
      return undefined;
    }
    return errors;
  }

  /**
   * @template {object} T
   * @this {(typeof BaseModel<T>) & (new (doc: T) => any)}
   * @param {Record<string, unknown>} variables
   * @param {Record<string, import('./Variable').default>} variablesDb
   * @param {object} options
   * @param {string[]} [options.createRealm]
   * @returns {Partial<T>}
   */
  static fromVariables(variables, variablesDb, options = {}) {
    const { createRealm } = options;
    /** @type {Partial<T>} */
    const doc = {};
    forEach(variables, (value, key) => {
      const variable = variablesDb[key];
      if (variable && variable.scopeName === this.scopeName) {
        variable.setValue(doc, value, { createRealm, allowImmutable: true });
      }
    });
    return doc;
  }
}

BaseModel.store = 'ddp';
BaseModel.logger = logger.create('models');
BaseModel.schema = {};
/** @type {object[]} */
BaseModel.constraints = [];
/** @type {string | undefined} */
BaseModel.scopeName = undefined;

export default BaseModel;
