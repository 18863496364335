// @ts-nocheck
import map from 'lodash/map';
import includes from 'lodash/includes';
import has from 'lodash/has';
import BaseModel from './BaseModel';
import {
  PARTICIPATION_ACTION__START,
  PARTICIPATION_FINAL_STATES,
  PARTICIPATION_STATES_AFTER_START,
} from '../constants';

const getBaseline = (participation) => {
  let baseline;
  if (participation.actions) {
    const n = participation.actions.length;
    for (let i = 0; i < n; i += 1) {
      const { type, payload } = participation.actions[i];
      if (payload.updated && has(payload.updated, 'trackDate')) {
        baseline = payload.updated.trackDate;
      }
      if (payload.removed && includes(payload.removed, 'trackDate')) {
        baseline = null;
      }
      if (type === PARTICIPATION_ACTION__START) {
        return baseline;
      }
    }
  }
  return baseline;
};

class Participation extends BaseModel {
  // eslint-disable-next-line class-methods-use-this
  set baseline(value) {
    // NOTE: Baseline is currently an evaluated property so we do not
    //       allow to overwrite it. However, we don't want a write attempt
    //       to result in error, that's why we provide a noop setter.
  }

  // NOTE: baseline is equal to trackDate of the first active track
  get baseline() {
    const baseline = getBaseline(this);
    Object.defineProperty(this, 'baseline', {
      value: baseline,
    });
    return baseline;
  }

  isStarted() {
    return includes(PARTICIPATION_STATES_AFTER_START, this.state);
  }

  isDischarged() {
    return includes(PARTICIPATION_FINAL_STATES, this.state);
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getComments() {
    const comments = [];

    if (this.actions) {
      const n = this.actions.length;
      for (let i = 0; i < n; i += 1) {
        let contentKey;
        const {
          meta: { comment, userId, timestamp, triggeredByWorker },
        } = this.actions[i];

        if (
          !comment &&
          (triggeredByWorker === 'AnswersSheets.onFailedConsent' ||
            triggeredByWorker === 'Activities.endParticipationOnConsentExpiry')
        ) {
          contentKey = 'tooltips:consent';
        }
        if (comment || contentKey) {
          comments.push({
            content: comment,
            userId,
            timestamp,
            contentKey,
          });
        }
      }
    }

    return comments;
  }
}

Participation.collection = 'Participations';
Participation.scopeName = '@participation';
/** @type {string[]} */
Participation.piiFields = [];

export default Participation;
