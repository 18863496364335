import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Project from '../../../common/models/Project';
import ProjectTrack from '../../../common/models/ProjectTrack';
import ProjectMilestone from '../../../common/models/ProjectMilestone';
import FormFieldSelect from '../../../components/forms/FormFieldSelect';
import FormFieldText from '../../../components/forms/FormFieldText';
import FormFieldDate from '../../../common/components/FormFieldDate';
import FormFieldNumber from '../../../common/components/FormFieldNumber';
// import FormFieldSwitch from '../../../components/forms/FormFieldSwitch';
import {
  useForm,
  useFormField,
  validateOneOf,
  validateRequired,
} from '../../../components/forms/hooks';

const SurveyLinkForm = forwardRef((props, forwardedRef) => {
  const { project, tracks, milestones } = props;
  const { t } = useTranslation();
  const surveyName = useFormField(null, []);
  const trackId = useFormField(tracks[0] ? tracks[0]._id : null, [
    validateRequired(t),
  ]);

  const milestoneOptions = useMemo(() => {
    return milestones
      .filter(
        (milestone) =>
          !trackId.input.value ||
          milestone.isAllowedInTrack(trackId.input.value),
      )
      .map(({ _id, name }) => ({
        value: _id,
        label: name,
      }));
  }, [trackId.input.value, milestones]);

  const milestoneId = useFormField(milestones[0] ? milestones[0]._id : null, [
    validateRequired(t),
    validateOneOf(
      t,
      milestoneOptions.map(({ value }) => value),
    ),
  ]);
  const dateStart = useFormField(project.getCurrentYearMonthDay(), []);
  const dateEnd = useFormField(null, [
    validateRequired(t),
    useCallback(
      (value) => {
        if (value && dateStart.input.value && value < dateStart.input.value) {
          return t('forms:dateEnd.errorCannotBeBeforeStartDate');
        }
        return undefined;
      },
      [t, dateStart.input.value],
    ),
  ]);
  // const inactive = useFormField(false, []);
  const expiresIn = useFormField(null, []);

  const trackOptions = useMemo(() => {
    return tracks.map(({ _id, name }) => ({
      value: _id,
      label: name,
    }));
  }, [tracks]);

  useForm(forwardedRef, {
    name: surveyName,
    trackId,
    milestoneId,
    dateStart,
    dateEnd,
    expiresIn,
  });

  return (
    <form className="stack-6">
      <FormFieldText
        label={t('forms:name.label')}
        input={surveyName.input}
        meta={surveyName.meta}
      />
      <FormFieldSelect
        label={t('forms:track.label')}
        input={trackId.input}
        meta={trackId.meta}
        options={trackOptions}
      />
      <FormFieldSelect
        label={t('forms:milestone.label')}
        input={milestoneId.input}
        meta={milestoneId.meta}
        options={milestoneOptions}
      />
      <FormFieldDate
        label={t('forms:dateStart.label')}
        input={dateStart.input}
        meta={dateStart.meta}
      />
      <FormFieldDate
        label={t('forms:dateEnd.label')}
        input={dateEnd.input}
        meta={dateEnd.meta}
        required
      />
      <FormFieldNumber
        label={t('forms:sessionDurationInSeconds.label')}
        input={expiresIn.input}
        meta={expiresIn.meta}
      />
      {/* <FormFieldSwitch
        label={t('forms:inactive.label')}
        input={inactive.input}
        meta={inactive.meta}
      /> */}
    </form>
  );
});

SurveyLinkForm.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.instanceOf(ProjectTrack)).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone))
    .isRequired,
};

export default SurveyLinkForm;
