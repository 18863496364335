import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { useReconcile } from '@zedoc/react-hooks';
import {
  useImperativeHandle,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

export class ValidationError extends Error {
  constructor(message, errors) {
    super(message);
    this.errors = errors;
  }
}

export function getFormErrors(formFields) {
  return pickBy(
    mapValues(formFields, (field) => field.meta.error),
    Boolean,
  );
}

export const validateRequired = (t) => {
  return useMemo(() => {
    return (value) => {
      if (!value) {
        return t('Value is required');
      }
      return undefined;
    };
  }, [t]);
};

/**
 * @param {(key: string) => string} t
 * @param {string[]} allowedValues
 * @returns {(value: unknown) => string | undefined}
 */
export const validateOneOf = (t, allowedValues) => {
  const reconciledAllowedValues = useReconcile(allowedValues);
  return useMemo(() => {
    return (value) => {
      if (!reconciledAllowedValues.includes(value)) {
        return t('Value is not allowed');
      }
      return undefined;
    };
  }, [t, reconciledAllowedValues]);
};

export function getFormValues(formFields) {
  return mapValues(formFields, (field) => field.input.value);
}

export function useForm(forwardedRef, fields) {
  const reconciledFields = useReconcile(fields);
  const { t } = useTranslation();

  return useImperativeHandle(
    forwardedRef,
    () => {
      return {
        submit: async () => {
          const errors = getFormErrors(reconciledFields);
          if (!isEmpty(errors)) {
            forEach(reconciledFields, (field) => {
              field.setSubmitFailed(true);
            });
            throw new ValidationError(
              t('confirmations:validateQuestionnaire.error'),
              errors,
            );
          }
          return getFormValues(reconciledFields);
        },
      };
    },
    [t, reconciledFields],
  );
}

export function useFormField(initialValue, validators = []) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const reconciledValidators = useReconcile(validators);

  const getError = useCallback(
    (newValue) => {
      if (reconciledValidators) {
        for (let i = 0; i < reconciledValidators.length; i += 1) {
          const newError = reconciledValidators[i](newValue);
          if (newError) {
            return newError;
          }
        }
      }
      return undefined;
    },
    [reconciledValidators],
  );

  const [error, setError] = useState(getError(initialValue));

  useEffect(() => {
    setError(getError(value));
    // NOTE: I only want to run this effect when getError changes,
    //       and skip running it when value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setError, getError]);

  const input = useMemo(() => {
    return {
      value,
      onChange: (newValue) => {
        setValue(newValue);
        setTouched(true);
        setError(getError(newValue));
        setSubmitFailed(false);
      },
    };
  }, [value, setValue, getError]);
  const meta = useMemo(() => {
    return {
      error,
      touched,
      submitFailed,
    };
  }, [error, touched, submitFailed]);
  return useMemo(() => {
    return {
      input,
      meta,
      setSubmitFailed,
    };
  }, [input, meta]);
}
