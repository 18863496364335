import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const getClassNameBySize = (size) => {
  switch (size) {
    case 'small':
      return 'h-8';
    case 'large':
      return 'h-12';
    default:
      return 'h-10';
  }
};

const Component = React.forwardRef(
  (
    {
      'data-testid': testId,
      id,
      'aria-haspopup': ariaHasPopup,
      'aria-owns': ariaOwns,
      'aria-autocomplete': ariaAutoComplete,
      'aria-controls': ariaControls,
      'aria-activedescendant': ariaActiveDescendant,
      'aria-expanded': ariaExpanded,
      role,
      autoComplete,
      className,
      name,
      type,
      inputMode,
      size,
      placeholder,
      autoFocus,
      disabled,
      minLength,
      maxLength,
      step,
      value,
      onChange,
      onBlur,
      onFocus,
    },
    forwardedRef,
  ) => (
    <input
      ref={forwardedRef}
      data-testid={testId}
      id={id}
      aria-haspopup={ariaHasPopup}
      aria-owns={ariaOwns}
      aria-autocomplete={ariaAutoComplete}
      aria-controls={ariaControls}
      aria-activedescendant={ariaActiveDescendant}
      aria-expanded={ariaExpanded}
      role={role}
      autoComplete={autoComplete}
      className={className}
      name={name}
      type={type}
      inputMode={inputMode}
      size={size}
      placeholder={placeholder}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      disabled={disabled}
      minLength={minLength}
      maxLength={maxLength}
      step={step}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  ),
);

const Input = React.forwardRef(
  (
    {
      'data-testid': testId,
      id,
      'aria-haspopup': ariaHasPopup,
      'aria-owns': ariaOwns,
      'aria-autocomplete': ariaAutoComplete,
      'aria-controls': ariaControls,
      'aria-activedescendant': ariaActiveDescendant,
      'aria-expanded': ariaExpanded,
      role,
      autoComplete,
      className,
      name,
      type,
      inputMode,
      size,
      placeholder,
      autoFocus,
      disabled,
      prefix,
      suffix,
      minLength,
      maxLength,
      step,
      value,
      onChange,
      onBlur,
      onFocus,
      validateStatus,
    },
    forwardedRef,
  ) => {
    const borderColor = useMemo(() => {
      switch (validateStatus) {
        case 'danger':
          return 'focus-within:ring-danger-800 border-danger-800';
        case 'warning':
          return 'focus-within:ring-warning border-warning';
        // case 'success':
        //   return 'focus-within:ring-success border-success';
        default:
          return `focus-within:ring-primary focus-within:border-primary border-input-border ${
            disabled ? '' : 'hover:border-primary'
          }`;
      }
    }, [validateStatus, disabled]);

    const containerClassName = `${className} ${getClassNameBySize(
      size,
    )} bg-surface rounded-md focus-within:ring-1 ${borderColor} transition-colors`;
    const sharedClassName = `zedoc-input-group__item ${
      !prefix && !suffix ? containerClassName : 'border-transparent'
    } bg-surface text-sm border focus:outline-none py-2 px-4 w-full disabled:bg-surface disabled:cursor-not-allowed disabled:text-on-disabled disabled:placeholder-on-disabled`;

    // TODO: Possibly round borders of prefix / suffix for prettier focus effect
    if (prefix || suffix) {
      return (
        <div className={`z-input-container border ${containerClassName}`}>
          <div className="cluster-0 h-full">
            {prefix}
            <Component
              ref={forwardedRef}
              data-testid={testId}
              id={id}
              aria-haspopup={ariaHasPopup}
              aria-owns={ariaOwns}
              aria-autocomplete={ariaAutoComplete}
              aria-controls={ariaControls}
              aria-activedescendant={ariaActiveDescendant}
              aria-expanded={ariaExpanded}
              role={role}
              autoComplete={autoComplete}
              className={sharedClassName}
              name={name}
              type={type}
              inputMode={inputMode}
              size={size}
              placeholder={placeholder}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              disabled={disabled}
              minLength={minLength}
              maxLength={maxLength}
              step={step}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            {suffix}
          </div>
        </div>
      );
    }

    return (
      <Component
        ref={forwardedRef}
        data-testid={testId}
        id={id}
        aria-haspopup={ariaHasPopup}
        aria-owns={ariaOwns}
        aria-autocomplete={ariaAutoComplete}
        aria-controls={ariaControls}
        aria-activedescendant={ariaActiveDescendant}
        aria-expanded={ariaExpanded}
        role={role}
        autoComplete={autoComplete}
        className={sharedClassName}
        name={name}
        type={type}
        inputMode={inputMode}
        size={size}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        minLength={minLength}
        maxLength={maxLength}
        step={step}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  },
);

const propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  'aria-haspopup': PropTypes.string,
  'aria-owns': PropTypes.string,
  'aria-autocomplete': PropTypes.string,
  'aria-controls': PropTypes.string,
  'aria-activedescendant': PropTypes.string,
  'aria-expanded': PropTypes.string,
  role: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
const defaultProps = {
  'data-testid': 'input',
  id: null,
  'aria-haspopup': null,
  'aria-owns': null,
  'aria-autocomplete': null,
  'aria-controls': null,
  'aria-activedescendant': null,
  'aria-expanded': null,
  role: null,
  autoComplete: null,
  className: '',
  name: null,
  type: 'text',
  inputMode: null,
  size: 'default',
  placeholder: null,
  autoFocus: false,
  disabled: false,
  minLength: null,
  maxLength: null,
  step: null,
  value: '',
  onChange: () => {},
  onBlur: () => {},
};

Component.propTypes = {
  ...propTypes,
};
Component.defaultProps = {
  ...defaultProps,
};

Input.propTypes = {
  ...propTypes,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
};
Input.defaultProps = {
  ...defaultProps,
  prefix: null,
  suffix: null,
};

export default Input;
