import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  PROJECT_SEARCH_PROJECT,
  PATIENT_SEARCH_PATIENT,
  ADMIN_SEARCH_USER,
  ADMIN_SEARCH_AUDIT_LOGS,
  ADMIN_SEARCH_PROJECT,
  ADMIN_SEARCH_REPORT,
  ADMIN_SEARCH_WORKFLOW,
  ADMIN_SEARCH_GROUP,
  ADMIN_SEARCH_DOMAIN,
  ADMIN_SEARCH_ROLE,
  ADMIN_SEARCH_VARIABLE,
  ORGANIZATION_SEARCH_QUESTIONNAIRE,
} from '../../common/permissions';
import { BRANDING_TYPE__CLINICIAN } from '../../common/constants/branding';
import { logout } from '../../store/ddpActions';
import { Forms, Recipients, Projects, Settings, SignOut } from '../../ui/icons';
import settings from '../../common/settings';
import Logo from '../../common/components/Logo';
import branding, { uiBranding } from '../../utils/branding';
import usePermission from '../../utils/usePermission';
import usePermissionSet, {
  ACCESS_EXTERNAL_PATIENT_SEARCH,
} from '../../utils/usePermissionSet';
import QuickAccess from '../QuickAccess';
import CurrentUser from '../../models/CurrentUser';
import NavigationItem from './NavigationItem';
import NavigationHelpdesk from './NavigationHelpdesk';
import { listItemClassName, listItemHoverClassName } from './helpers';

const QuickAccessButton = forwardRef(({ onClick }, forwardedRef) => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.dir();
  const isLanguageDirectionLTR = languageDirection === 'ltr';
  return (
    <button
      ref={forwardedRef}
      type="button"
      className={`cluster-2 justify-between items-center w-full ${listItemClassName} ${listItemHoverClassName}`}
      onClick={onClick}
    >
      <span>{t('quickAccess')}</span>
      {isLanguageDirectionLTR ? (
        <ChevronRightIcon className="w-4 h-4 mis-4" />
      ) : (
        <ChevronLeftIcon className="w-4 h-4 mis-4" />
      )}
    </button>
  );
});

QuickAccessButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Navigation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validate = useSelector(
    CurrentUser.select.getCurrentPermissionsValidator(),
  );
  const canBrowseProjects = usePermission([PROJECT_SEARCH_PROJECT]);
  const canBrowsePatients = usePermission([PATIENT_SEARCH_PATIENT]);
  const canLookupExternalPatientDatabase = usePermissionSet(
    ACCESS_EXTERNAL_PATIENT_SEARCH,
  );
  const canBrowseQuestionnaires = usePermission([
    ORGANIZATION_SEARCH_QUESTIONNAIRE,
  ]);
  const canBrowseSettings =
    validate(ADMIN_SEARCH_USER) ||
    validate(ADMIN_SEARCH_AUDIT_LOGS) ||
    validate(ADMIN_SEARCH_PROJECT) ||
    validate(ADMIN_SEARCH_REPORT) ||
    validate(ADMIN_SEARCH_WORKFLOW) ||
    validate(ADMIN_SEARCH_GROUP) ||
    validate(ADMIN_SEARCH_DOMAIN) ||
    validate(ADMIN_SEARCH_ROLE) ||
    validate(ADMIN_SEARCH_VARIABLE);
  const userId = useSelector(CurrentUser.select.userId());

  const handleOnLogout = () => {
    dispatch(logout());
  };

  return (
    <aside className="py-4 flex flex-col border-ie border-divider">
      <Link
        to="/"
        className={`mb-6 ${
          uiBranding === BRANDING_TYPE__CLINICIAN ? 'h-7' : 'h-5'
        }`}
      >
        <Logo branding={uiBranding} />
      </Link>
      <div className="stack-2 overflow-y-auto">
        <ul className="stack-2">
          {canBrowseProjects && (
            <NavigationItem
              testId="menu-item-projects"
              label={t('projects')}
              icon={<Projects />}
              items={[
                {
                  testId: 'menu-item-all-projects',
                  label: t('allProjects'),
                  to: '/projects',
                },
                {
                  testId: 'menu-item-pinned-projects',
                  label: t('pinnedProjects'),
                  to: '/pinned-projects',
                },
              ]}
            />
          )}
          {canBrowsePatients && (
            <NavigationItem
              testId="menu-item-patients"
              label={t('recipients', {
                context: branding,
              })}
              icon={<Recipients />}
              items={[
                {
                  testId: 'menu-item-all-patients',
                  label: t('allRecipients', { context: branding }),
                  to: '/patients',
                },
                {
                  testId: 'menu-item-patients-in-project',
                  label: t('recipientsInProject', { context: branding }),
                  to: '/patients-in-project',
                },
                canLookupExternalPatientDatabase &&
                  settings.public.externalPatientSearch && {
                    testId: 'menu-item-external-patients',
                    label: 'External Patients',
                    to: '/external-patients',
                  },
              ].filter((x) => x)}
            />
          )}
          {canBrowseQuestionnaires && (
            <NavigationItem
              testId="menu-item-forms"
              label={t('allForms')}
              icon={<Forms />}
              to="/forms"
            />
          )}
          {canBrowseSettings && (
            <NavigationItem
              testId="menu-item-my-settings"
              label={t('settings')}
              icon={<Settings />}
              items={[
                validate(ADMIN_SEARCH_USER) && {
                  testId: 'menu-item-users',
                  label: t('users'),
                  to: '/settings/users',
                },
                validate(ADMIN_SEARCH_AUDIT_LOGS) && {
                  testId: 'menu-item-logs',
                  label: t('logs'),
                  to: '/settings/logs',
                },
                validate(ADMIN_SEARCH_PROJECT) && {
                  testId: 'menu-item-projects',
                  label: t('projects'),
                  to: '/settings/projects',
                },
                validate(ADMIN_SEARCH_REPORT) && {
                  testId: 'menu-item-reports',
                  label: t('reports'),
                  to: '/settings/reports',
                },
                validate(ADMIN_SEARCH_WORKFLOW) && {
                  testId: 'menu-item-eapp',
                  label: t('eApp'),
                  to: '/settings/eapp',
                },
                validate(ADMIN_SEARCH_DOMAIN) && {
                  testId: 'menu-item-domains',
                  label: t('domains'),
                  to: '/settings/domains',
                },
                validate(ADMIN_SEARCH_GROUP) && {
                  testId: 'menu-item-groups',
                  label: t('groups'),
                  to: '/settings/groups',
                },
                validate(ADMIN_SEARCH_ROLE) && {
                  testId: 'menu-item-roles',
                  label: t('roles'),
                  to: '/settings/roles',
                },
                validate(ADMIN_SEARCH_VARIABLE) && {
                  testId: 'menu-item-variables',
                  label: t('variables'),
                  to: '/settings/variables',
                },
              ].filter((item) => item)}
            />
          )}
        </ul>
        <hr className="mx-4 border-divider" />
        <ul className="stack-2">
          <NavigationHelpdesk />
          {userId && (
            <NavigationItem
              testId="menu-item-my-log-out"
              label={t('signOut')}
              icon={<SignOut />}
              onClick={handleOnLogout}
            />
          )}
        </ul>
      </div>
      <div className="mt-auto stack-2">
        <hr className="mx-4 border-divider" />
        {userId && <QuickAccess as={QuickAccessButton} position="right-end" />}
      </div>
    </aside>
  );
};

export default Navigation;
