import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isoLangs } from '@zedoc/iso-langs';
import { apiZedocListTranslationOptionsForAnswersSheet } from '../../common/api/zedoc';
import Select from '../Select';
import useList from '../../utils/useList';

const TranslationsSelect = ({
  type,
  answersSheetId,
  answersSheetLanguages,
  value,
  onChange,
  valueAsLanguage,
}) => {
  const { i18n } = useTranslation();
  const uiLanguage = i18n.language;

  const { items } = useList(
    answersSheetId &&
      apiZedocListTranslationOptionsForAnswersSheet.withParams({
        answersSheetId,
        resultId: '$meta.id',
      }),
  );

  const originalQuestionnaireLanguage = useMemo(() => {
    return items.find(({ version }) => version === '0');
  }, [items]);

  const translations = useMemo(() => {
    return items.filter(
      ({ language }) =>
        uiLanguage === language || answersSheetLanguages.includes(language),
    );
  }, [items, uiLanguage, answersSheetLanguages]);

  const translationThatMatchesUILanguage = useMemo(
    () => translations.find(({ language }) => language === uiLanguage),
    [translations, uiLanguage],
  );

  const languageOptions = useMemo(() => {
    const generateTranslations = ({ language, translationId }) => {
      return {
        value: valueAsLanguage ? language : translationId,
        label: isoLangs[language]?.name,
      };
    };

    if (!translationThatMatchesUILanguage && originalQuestionnaireLanguage) {
      const translationThatMatchesBaseQuestionnaireLanguage = translations.some(
        (translation) =>
          translation.language === originalQuestionnaireLanguage.language,
      );

      // NOTE: If no translation that matches UI language is present than use questionnaire language instead
      if (!translationThatMatchesBaseQuestionnaireLanguage) {
        return [originalQuestionnaireLanguage, ...translations].map(
          generateTranslations,
        );
      }
    }

    return translations.map(generateTranslations);
  }, [
    translations,
    translationThatMatchesUILanguage,
    originalQuestionnaireLanguage,
    valueAsLanguage,
  ]);

  const handleCurrentLanguageChange = (language) => onChange(language);
  useEffect(() => {
    if (translationThatMatchesUILanguage) {
      const { language, translationId } = translationThatMatchesUILanguage;
      onChange(valueAsLanguage ? language : translationId);
    } else if (originalQuestionnaireLanguage) {
      onChange(
        valueAsLanguage
          ? originalQuestionnaireLanguage.language
          : originalQuestionnaireLanguage.translationId,
      );
    }
  }, [
    onChange,
    translationThatMatchesUILanguage,
    originalQuestionnaireLanguage,
    valueAsLanguage,
  ]);

  return languageOptions.length > 1 ? (
    <Select
      type={type}
      options={languageOptions}
      onChange={handleCurrentLanguageChange}
      value={value}
    />
  ) : null;
};

TranslationsSelect.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  answersSheetId: PropTypes.string,
  answersSheetLanguages: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  valueAsLanguage: PropTypes.bool,
};

TranslationsSelect.defaultProps = {
  type: 'secondary',
  answersSheetId: null,
  answersSheetLanguages: [],
  value: null,
  onChange: () => {},
  valueAsLanguage: false,
};

export default TranslationsSelect;
