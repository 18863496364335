import React from 'react';
import { Link } from 'react-router-dom';
import { BRANDING_TYPE__CLINICIAN } from '../../../common/constants/branding';
import Logo from '../../../common/components/Logo';
import { uiBranding } from '../../../utils/branding';

const EntryLayoutLogo = () => {
  return (
    <Link
      to="/entry/signIn"
      className="focus:outline-none focus:ring-2 focus:ring-primary rounded-lg p-2"
    >
      <Logo
        className={uiBranding === BRANDING_TYPE__CLINICIAN ? 'h-12' : 'h-6'}
        branding={uiBranding}
      />
    </Link>
  );
};

export default EntryLayoutLogo;
