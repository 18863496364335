/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import PopoverAntd from '../../../common/components/Popover';
import Popover from '../../Popover';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import FormFieldWrapperNewPresentation from '../FormFieldWrapper';
import Input from '../../inputs/Input';
import Button from '../../Button';
import ValidationRules from '../ValidationRules';
import useValidateStatus from '../../../utils/useValidateStatus';
import { ERROR_TYPE__REQUIRED } from '../../../utils/useForm/constants';

const FormFieldText = React.forwardRef(
  (
    {
      onGenerateValue,
      id,
      name,
      label,
      required,
      tooltip,
      meta,
      extra,
      size,
      showAsterisk,
      input,
      onFocus,
      schema,
      validationRules,
      popover,
      isNewPresentation,
      readOnly,
      ...rest
    },
    forwardRef,
  ) => {
    const { t } = useTranslation();
    const validateStatus = useValidateStatus({ meta, required });
    const [isValueGenerating, setIsValueGenerating] = useState(false);

    const props = {
      ...input,
      value: input.value === null ? '' : input.value,
      onChange: (event) =>
        input.onChange ? input.onChange(event.target.value) : () => {},
      ...rest,
    };
    const { value, onChange } = input;

    const handleOnClick = useCallback(() => {
      if (onGenerateValue) {
        setIsValueGenerating(true);
        Promise.resolve(onGenerateValue(value))
          .then((newValue) => {
            if (onChange && typeof newValue === 'string') {
              onChange(newValue);
            }
            setIsValueGenerating(false);
          })
          .catch(() => {
            setIsValueGenerating(false);
          });
      }
    }, [value, onChange, onGenerateValue, setIsValueGenerating]);

    const component = readOnly ? (
      value || <span>&ndash;</span>
    ) : (
      <Input
        // NOTE: This can be overwritten if testid is provided via props.
        data-testid={`form-field-${label}`}
        ref={forwardRef}
        size={size}
        id={id}
        name={name}
        onFocus={onFocus}
        validateStatus={validateStatus}
        isNewPresentation={isNewPresentation}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );

    // NOTE: We use this flag until all inputs components UI is updated
    if (isNewPresentation) {
      return (
        <FormFieldWrapperNewPresentation
          htmlFor={id}
          id={id}
          label={
            onGenerateValue ? (
              <div className="cluster-4">
                <span>{label}</span>
                <Button
                  data-testid={
                    props['data-testid']
                      ? `${props['data-testid']}-button-generate`
                      : 'button-generate'
                  }
                  type="ghost"
                  loading={isValueGenerating}
                  disabled={props.disabled}
                  onClick={handleOnClick}
                >
                  {t('generate')}
                </Button>
              </div>
            ) : (
              label
            )
          }
          tooltip={tooltip}
          extra={extra}
          required={required}
          meta={
            !validationRules || meta.type === ERROR_TYPE__REQUIRED ? meta : {}
          }
          size={size}
          showAsterisk={showAsterisk}
          disabled={props.disabled}
        >
          {validationRules ? (
            <PopoverAntd
              title={validationRules.title}
              placement="left"
              content={
                <ValidationRules
                  schema={schema}
                  property={name}
                  value={value}
                />
              }
              trigger="focus"
            >
              {component}
            </PopoverAntd>
          ) : (
            component
          )}
        </FormFieldWrapperNewPresentation>
      );
    }

    return (
      <FormFieldWrapper
        htmlFor={id}
        id={id}
        label={label}
        labelExtra={
          onGenerateValue && (
            <Button
              data-testid={
                props['data-testid']
                  ? `${props['data-testid']}-button-generate`
                  : 'button-generate'
              }
              type="ghost"
              loading={isValueGenerating}
              disabled={props.disabled}
              onClick={handleOnClick}
            >
              {t('generate')}
            </Button>
          )
        }
        tooltip={tooltip}
        extra={extra}
        required={required}
        meta={
          !validationRules || meta.type === ERROR_TYPE__REQUIRED ? meta : {}
        }
        size={size}
        showAsterisk={showAsterisk}
        disabled={props.disabled}
      >
        {popover ? (
          <Popover
            title={popover.title}
            type={popover.type}
            placement={popover.placement}
            content={popover.content}
            visible={popover.visible}
            size={popover.size}
            onCancel={popover.onCancel}
            onOk={popover.onOk}
          >
            <Input
              // NOTE: This can be overwritten if testid is provided via props.
              data-testid={`form-field-${label}`}
              ref={forwardRef}
              size={size}
              id={id}
              name={name}
              onFocus={onFocus}
              validateStatus={validateStatus}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          </Popover>
        ) : (
          <Input
            // NOTE: This can be overwritten if testid is provided via props.
            data-testid={`form-field-${label}`}
            ref={forwardRef}
            size={size}
            id={id}
            name={name}
            onFocus={onFocus}
            validateStatus={validateStatus}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      </FormFieldWrapper>
    );
  },
);

export default FormFieldText;
