import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';
import { getPattern } from '@zedoc/check-schema';
import User from '../../../common/models/User';
import PermissionsDomain from '../../../common/models/PermissionsDomain';
import {
  ADMIN_UPDATE_USER_PHONES,
  ADMIN_UPDATE_USER_EMAILS,
} from '../../../common/permissions';
import Role from '../../../common/models/Role';
import UsersGroup from '../../../common/models/UsersGroup';
import usePermission from '../../../utils/usePermission';
import DialogWithSteps from '../../DialogWithSteps';
import useForm from '../../../utils/useForm';
import renderFormField from '../../../utils/renderFormField';
import Button from '../../Button';
import Input from '../../Input';

const EMAIL_MAX_LENGTH = 254;

const EditUser = ({
  open,
  user,
  onCancel,
  isConfirmLoading,
  disabled,
  rolesDb,
  groupsDb,
  permissionsDomainsOptions,
  groupedRolesOptions,
  groupedGroupsOptions,
  createUserRealm,
  deleteUserRealm,
  onUpdate,
  onCreate,
}) => {
  const { t } = useTranslation();

  const canEditPhones = usePermission(ADMIN_UPDATE_USER_PHONES);
  const canEditEmails = usePermission(ADMIN_UPDATE_USER_EMAILS);
  const create = !user;

  const [isEditing, setIsEditing] = useState(false);

  const {
    form: formFirstStep,
    fields: fieldFirstStep,
    handleSubmit: handleSubmitFirstStep,
    handleResetForm: handleResetFormFirstStep,
    isValid: isValidFirstStep,
  } = useForm({
    inputs: {
      name: {
        testId: 'form-field-name',
        label: t('forms:name.label'),
        type: 'text',
        defaultValue: user?.getFullName(),
        readOnly: !isEditing && !create,
        disabled,
        schema: {
          type: String,
        },
      },
      email: {
        testId: 'form-field-email',
        label: t('forms:email.label'),
        type: 'email',
        defaultValue: user?.getEmailAddress(),
        readOnly: !isEditing && !create,
        disabled: !create && (!canEditEmails || disabled),
        maxLength: EMAIL_MAX_LENGTH,
        schema: {
          type: String,
          validationRules: [
            {
              regEx: new RegExp(getPattern('email')),
              label: t('forms:email.error'),
            },
          ],
        },
      },
      resetPassword: {
        testId: 'form-field-resetPassword',
        label: create ? t('sendEnrollmentEmail') : t('sendPasswordResetEmail'),
        type: 'checkbox',
        defaultValue: !user,
        disabled: disabled || (!isEditing && !create),
        schema: {
          type: Boolean,
          optional: true,
        },
      },
      phone: {
        testId: 'form-field-phone',
        label: t('forms:phone.label'),
        type: 'phone',
        defaultValue: user?.getPhoneNumber(),
        readOnly: !isEditing && !create,
        disabled: !create && (!canEditPhones || disabled),
        schema: {
          type: String,
          format: 'phone',
          optional: true,
        },
      },
      locked: {
        testId: 'form-field-locked',
        label: t('lockAccount'),
        type: 'checkbox',
        defaultValue: user?.isLocked(),
        disabled: disabled || (!isEditing && !create),
        schema: {
          type: Boolean,
          optional: true,
        },
      },
    },
  });

  const getOptionsApplicableToRole = useCallback(
    (roleId) => {
      return permissionsDomainsOptions.filter(
        ({ value: domainId }) =>
          rolesDb[roleId]?.isApplicableTo(domainId) &&
          PermissionsDomain.contains(createUserRealm, domainId),
      );
    },
    [rolesDb, permissionsDomainsOptions, createUserRealm],
  );

  const getOptionsApplicableToGroup = useCallback(
    (groupId) => {
      return permissionsDomainsOptions.filter(
        ({ value: domainId }) =>
          groupsDb[groupId]?.belongsTo === domainId &&
          PermissionsDomain.contains(createUserRealm, domainId),
      );
    },
    [groupsDb, permissionsDomainsOptions, createUserRealm],
  );

  const rolesInitialValue = useMemo(() => {
    return user
      ?.getRoles()
      .filter(({ id, appliesTo }) => {
        if (!PermissionsDomain.contains(createUserRealm, appliesTo)) {
          return false;
        }
        const applicableOptions = getOptionsApplicableToRole(id);
        return !isEmpty(applicableOptions);
      })
      .map((role) => ({
        roleId: role.id,
        domainId: role.appliesTo,
      }));
  }, [user, getOptionsApplicableToRole, createUserRealm]);

  const groupsInitialValue = useMemo(() => {
    return user
      ?.getGroups()
      .filter(({ id, appliesTo }) => {
        if (!PermissionsDomain.contains(createUserRealm, appliesTo)) {
          return false;
        }
        const applicableOptions = getOptionsApplicableToGroup(id);
        return !isEmpty(applicableOptions);
      })
      .map((group) => ({
        groupId: group.id,
      }));
  }, [user, createUserRealm, getOptionsApplicableToGroup]);

  // TODO: Translate
  const {
    form: formSecondStep,
    fields: fieldsSecondStep,
    handleSubmit: handleSubmitSecondStep,
    handleResetForm: handleResetFormSecondStep,
    isValid: isValidSecondStep,
    setValue: setValueSecondStep,
  } = useForm({
    inputs: {
      roles: {
        label: t('forms:addByRole.label'),
        createText: t('addRole'),
        type: 'collection',
        disabled: !isEditing && !create,
        defaultValue: rolesInitialValue,
        schema: {
          optional: true,
        },
        items: ({ form, itemIndex }) => {
          const rolesValues = form.roles ? form.roles[itemIndex] : {};
          const canRemove =
            !rolesValues.domainId ||
            PermissionsDomain.contains(deleteUserRealm, rolesValues.domainId);

          const filteredPermissionsDomainsOptions = getOptionsApplicableToRole(
            rolesValues.roleId,
          );

          return {
            roleId: {
              testId: 'form-field-roles',
              placeholder: t('roles', { count: 1 }),
              type: 'react-select',
              options: groupedRolesOptions,
              defaultValue: user?.roles?.[itemIndex]?.id,
              readOnly: !isEditing && !create,
              onAfterChange: (roleId) => {
                const ids = getOptionsApplicableToRole(roleId).map(
                  ({ value }) => value,
                );
                const fundamentalDomains =
                  PermissionsDomain.extractFundamentalDomains(ids);
                if (fundamentalDomains.length === 1) {
                  const roles = [...formSecondStep.roles];
                  roles[itemIndex] = {
                    roleId,
                    domainId: fundamentalDomains[0],
                  };
                  setValueSecondStep('roles', roles);
                }
              },
              disabled: disabled || canRemove === false,
              canRemove,
              schema: {
                type: String,
              },
            },
            domainId: {
              testId: 'form-field-roles',
              placeholder: t('domains', { count: 1 }),
              type: 'react-select',
              options: filteredPermissionsDomainsOptions,
              defaultValue: user?.roles?.[itemIndex]?.appliesTo,
              readOnly: !isEditing && !create,
              disabled: disabled || canRemove === false,
              canRemove,
              schema: {
                type: String,
              },
            },
          };
        },
      },
      groups: {
        label: t('forms:addByGroup.label'),
        createText: t('addGroup'),
        type: 'collection',
        disabled: !isEditing && !create,
        defaultValue: groupsInitialValue,
        items: ({ form, itemIndex }) => {
          const groupsValues = form.groups ? form.groups[itemIndex] : {};
          const group = groupsDb[groupsValues.groupId];
          const canRemove =
            group &&
            (!group.belongsTo ||
              PermissionsDomain.contains(deleteUserRealm, group.belongsTo));

          return {
            groupId: {
              testId: 'form-field-groups',
              placeholder: t('groups', { count: 1 }),
              type: 'react-select',
              options: groupedGroupsOptions,
              defaultValue: user?.groups?.[itemIndex]?.id,
              readOnly: !isEditing && !create,
              disabled: disabled || canRemove === false,
              canRemove,
              schema: {
                type: String,
              },
            },
          };
        },
        renderExtra: (idx) => {
          if (idx < 0) {
            return null;
          }

          const usersGroup = formSecondStep?.groups?.[idx]
            ? groupsDb[formSecondStep.groups[idx].groupId]
            : null;

          if (!usersGroup) {
            return null;
          }

          return (
            <div className="grid gap-4 grid-cols-2">
              <div className="stack-6">
                {usersGroup?.roles?.map((role) => (
                  <Input key={role.id} value={role.name} disabled />
                ))}
              </div>
              <Input value={usersGroup?.belongsTo} disabled />
            </div>
          );
        },
      },
    },
    onSubmit: () => {
      const { roles } = formSecondStep;
      formSecondStep.roles = roles.filter((r) => !isEmpty(r));
      const data = { ...formFirstStep, ...formSecondStep };
      const groups = map(data.groups, ({ groupId }) => groupId);

      if (user) {
        return onUpdate({
          ...data,
          groups,
          userId: user._id,
        });
      }
      return onCreate({
        ...data,
        groups,
      });
    },
  });

  const getField = (fieldName) =>
    fieldFirstStep.find(({ name }) => name === fieldName);

  const handleOnCancel = () => {
    handleResetFormFirstStep();
    handleResetFormSecondStep();
    setIsEditing(false);
    onCancel();
  };

  const handleOnEdit = () => {
    if (isEditing) {
      setIsEditing(false);
      handleResetFormFirstStep();
      handleResetFormSecondStep();
    } else {
      setIsEditing(true);
    }
  };

  return (
    <DialogWithSteps
      data-testid="dialog-edit-user"
      title={user ? t('editUser') : t('addUser')}
      size="large"
      visible={open}
      okText={user ? t('update') : t('add')}
      onNext={handleSubmitFirstStep}
      onOk={handleSubmitSecondStep}
      onCancel={handleOnCancel}
      isOkDisabled={!isValidSecondStep || (!create && !isEditing)}
      loading={isConfirmLoading}
      steps={[
        {
          key: 'step1',
          content: (
            <form onSubmit={handleSubmitFirstStep} className="stack-6">
              <div className="cluster-2 justify-between items-center">
                <span className="text-lg font-medium">{t('personalInfo')}</span>
                {!create && (
                  <Button
                    data-testid={isEditing ? 'button-cancel' : 'button-edit'}
                    type="ghost"
                    onClick={handleOnEdit}
                  >
                    {isEditing ? t('cancel') : t('edit')}
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-2 gap-6">
                {renderFormField(getField('name'))}
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 items-end">
                {renderFormField(getField('email'))}
                {/* NOTE: This is to visually align "resetPassword" checkbox with "name" text box */}
                <div className="mb-2">
                  {renderFormField(getField('resetPassword'))}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                {renderFormField(getField('phone'))}
              </div>
              <div className="grid grid-cols-2 gap-6">
                {renderFormField(getField('locked'))}
              </div>
            </form>
          ),
          isValid: isValidFirstStep,
        },
        {
          key: 'step2',
          content: (
            <form onSubmit={handleSubmitSecondStep} className="stack-6">
              <div className="cluster-2 justify-between items-center">
                <span className="text-lg font-medium">
                  {t('rolesAndPermissions')}
                </span>
                {!create && (
                  <Button
                    data-testid={isEditing ? 'button-cancel' : 'button-edit'}
                    type="ghost"
                    onClick={handleOnEdit}
                  >
                    {isEditing ? t('cancel') : t('edit')}
                  </Button>
                )}
              </div>
              {renderFormField(
                fieldsSecondStep.find(({ name }) => name === 'roles'),
              )}
              {renderFormField(
                fieldsSecondStep.find(({ name }) => name === 'groups'),
              )}
            </form>
          ),
        },
      ]}
    />
  );
};

EditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(User),
  rolesDb: PropTypes.objectOf(PropTypes.instanceOf(Role)),
  groupsDb: PropTypes.objectOf(PropTypes.instanceOf(UsersGroup)),
  groupedRolesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  groupedGroupsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  permissionsDomainsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  createUserRealm: PropTypes.arrayOf(PropTypes.string),
  deleteUserRealm: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  isConfirmLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditUser.defaultProps = {
  user: null,
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
  rolesDb: {},
  groupsDb: {},
  groupedRolesOptions: [],
  groupedGroupsOptions: [],
  permissionsDomainsOptions: [],
  createUserRealm: [],
  deleteUserRealm: [],
  isConfirmLoading: false,
  disabled: false,
};

export default EditUser;
